import { Controller } from "stimulus"
import { Ajax } from "../ajax"

export default class extends Controller {

  static targets = [
    "question",
    "sample",
    "answer",
    "solution",
    "sampleHeader",
    "quiz",
    "explanation",
    "submitButton",
    "openQuestionSolution",
    "openQuestionAnswer"
  ]

  static values = { 
    answered: Boolean,
    nextUrl: String,
    c: Boolean,
    id: Number,
    parentId: Number,
    answerUrl: String,
    nextQuestionQuerySelector: String
  }

  toggle(e) {

    if (!this.answeredValue) {
      e.currentTarget.classList.toggle("checked")

      let index = this.solutionTargets.indexOf(e.currentTarget)

      if (e.currentTarget.classList.contains("checked")) {
        this.answerTargets[index].classList.add("checked")
        this.answerTargets[index].classList.add("text-purple-600")
        this.answerTargets[index].classList.remove("text-transparent")
      } else {
        this.answerTargets[index].classList.remove("checked")
        this.answerTargets[index].classList.add("text-transparent")
        this.answerTargets[index].classList.remove("text-purple-600")
      }

    }

  }

  showOpenQuestionSolution() {
    this.openQuestionSolutionTargets.forEach((hidden) => hidden.classList.remove("hidden"))
  }

  remoteAnswer(e) {
    let controller = this

    console.log("ANSWER")

    let open_question_answer = ""

    if (this.hasOpenQuestionAnswerTarget) {
      open_question_answer = this.openQuestionAnswerTarget.value
    }

    Ajax.post(this.answerUrlValue, {
      answer: this.answerTargets.sort((a, b) => a.dataset.position - b.dataset.position).map((answer) => answer.classList.contains("checked")),
      question_id: this.idValue,
      parent_id: this.parentIdValue,
      open_question_answer: open_question_answer
    }).then(function(response) {
      return response.text()
    }).then(function(response) {
      document.querySelector(controller.nextQuestionQuerySelectorValue).innerHTML = response
    })        

  }

  remoteNext(e) {
    let controller = this

    Ajax.get(this.nextUrlValue).then(function(response) {
      return response.text()
    }).then(function(response) {
      document.querySelector(controller.nextQuestionQuerySelectorValue).innerHTML = response
    })        

  }

  answer(e) {

    if (this.answeredValue) {

      if (this.hasNextUrlValue) {
        fetch(this.nextUrlValue).then(function(response) {
          return response.text()
        }).then(function(response) {
          document.getElementById("quiz-target").innerHTML = response
        })        
      }

      return
    }

    this.answeredValue = true
    let all_correct = true

    if (this.hasSampleHeaderTarget) {
      this.sampleHeaderTarget.classList.remove('text-transparent')
      this.sampleHeaderTarget.classList.add('text-purple-600')
    }

    if (this.hasQuizTarget) {
      this.quizTarget.classList.add('answered')
    } else {
      console.log("NO QUIZ TARGET")
    }

    this.solutionTargets.forEach((solution, index) => {
      this.sampleTargets[index].classList.remove('opacity-50')

      if (solution.dataset.x === 'c' && solution.classList.contains('checked')) {
        // solution.classList.add('true-positive')
        this.sampleTargets[index].classList.add("text-purple-600")
        this.sampleTargets[index].classList.remove("text-transparent")
      } else if (solution.dataset.x !== 'c' && !solution.classList.contains('checked')) {
        // solution.classList.add('true-negative')
      } else if (solution.dataset.x !== 'c' && solution.classList.contains('checked')) {
        // solution.classList.add('false-positive')
        all_correct = false
      } else {
        // solution.classList.add('false-positive')
        this.sampleTargets[index].classList.add("text-purple-600")
        this.sampleTargets[index].classList.remove("text-transparent")
        all_correct = false
      }

    })

    if (this.hasExplanationTarget) {
      this.explanationTarget.innerHTML = window.data.explanations[this.idValue].explanation
    }

    if (all_correct) {
      this.cValue = true

      if (this.hasQuizTarget) {
        this.quizTarget.classList.add('correct')
      }
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.innerHTML = "Weiter"
    }

    window.dispatchEvent(new CustomEvent("single-answered"))
  }

}
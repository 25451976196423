export class Ajax {
  static ajaxActive = 0

  static inc() {
    Ajax.ajaxActive++
  }

  static dec() {
    Ajax.ajaxActive--
  }

  static post(url, data) {
    let headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    let csrfTokenTag = document.head.querySelector("[name='csrf-token']")

    if (csrfTokenTag) {
      headers['X-CSRF-TOKEN'] = csrfTokenTag.getAttribute("content")
    }

    Ajax.inc()

    let request = fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(data) 
    })

    request.then(Ajax.dec, Ajax.dec)

    return request
  }

  static get(url) {
    let headers = {
      'X-Requested-With': 'XMLHttpRequest'
    }

    Ajax.inc()

    let request = fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: headers,
    })

    request.then(Ajax.dec, Ajax.dec)

    return request
  }
}
